<template>
  <div id="app">
    <Stage></Stage>
  </div>
</template>

<script>
import Stage from './vuejs/components/Stage.vue'
require('./js/util.js')

export default {
  name: 'App',
  data: function () {
    return {
      mousePosX: 0
    }
  },
  components: {
    Stage
  }
}

</script>

<style>
#app {
  position: absolute;
  top:0;
  left:0;
  width: 100%; height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #a1a9ff;
  text-decoration: none;
}
a:hover {
  color: #dcdfff;
}
a:active {
  color: #7f87ff;
}
a i {
  vertical-align: -webkit-baseline-middle;
  margin-right: 0.25rem;
}
</style>
