import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createMemoryHistory } from "vue-router";
import jsonContent from './storage/content.json';
import Cube from "@/vuejs/components/Cube.vue";
const pages = Object.keys(jsonContent);
var routes = [];
pages.forEach(
    function (page) {
        routes.push({ path: '/' + page, component: Cube });
    }
)

const router = createRouter({
    history: createMemoryHistory(),
    routes
});

const app = createApp(App);
app.use(router);
app.mount('#app');


